import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c70f1738 = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _295ff2fb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _fc9ff2b2 = () => interopDefault(import('../pages/license-holders/index.vue' /* webpackChunkName: "pages/license-holders/index" */))
const _19f0d3dc = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _933b91b4 = () => interopDefault(import('../pages/setup.vue' /* webpackChunkName: "pages/setup" */))
const _6f429e00 = () => interopDefault(import('../pages/courses/_courseId/_dateId.vue' /* webpackChunkName: "pages/courses/_courseId/_dateId" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/courses",
    component: _c70f1738,
    name: "courses___de"
  }, {
    path: "/it",
    component: _295ff2fb,
    name: "index___it"
  }, {
    path: "/license-holders",
    component: _fc9ff2b2,
    name: "license-holders___de"
  }, {
    path: "/login",
    component: _19f0d3dc,
    name: "login___de"
  }, {
    path: "/setup",
    component: _933b91b4,
    name: "setup___de"
  }, {
    path: "/it/courses",
    component: _c70f1738,
    name: "courses___it"
  }, {
    path: "/it/license-holders",
    component: _fc9ff2b2,
    name: "license-holders___it"
  }, {
    path: "/it/login",
    component: _19f0d3dc,
    name: "login___it"
  }, {
    path: "/it/setup",
    component: _933b91b4,
    name: "setup___it"
  }, {
    path: "/it/courses/:courseId/:dateId?",
    component: _6f429e00,
    name: "courses-courseId-dateId___it"
  }, {
    path: "/courses/:courseId/:dateId?",
    component: _6f429e00,
    name: "courses-courseId-dateId___de"
  }, {
    path: "/",
    component: _295ff2fb,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
