export const vueI18n = {}
export const vueI18nLoader = false
export const locales = [{"code":"de","name":"Deutsch","iso":"de-DE","file":"de.ts"},{"code":"it","name":"Italiano","iso":"it-IT","file":"it.ts"}]
export const defaultLocale = "de"
export const defaultDirection = "ltr"
export const routesNameSeparator = "___"
export const defaultLocaleRouteNameSuffix = "default"
export const strategy = "prefix_except_default"
export const lazy = true
export const langDir = "/var/www/academy-sign-app/assets/lang"
export const rootRedirect = null
export const detectBrowserLanguage = {"useCookie":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"academy_lang_i18n","cookieSecure":false,"alwaysRedirect":true,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false}
export const differentDomains = false
export const seo = false
export const baseUrl = ""
export const vuex = {"moduleName":"i18n","syncLocale":true,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const skipSettingLocaleOnNavigate = false
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = false
export const MODULE_NAME = "nuxt-i18n"
export const LOCALE_CODE_KEY = "code"
export const LOCALE_ISO_KEY = "iso"
export const LOCALE_DIR_KEY = "dir"
export const LOCALE_DOMAIN_KEY = "domain"
export const LOCALE_FILE_KEY = "file"
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = "nuxtI18n"
export const localeCodes = ["de","it"]
export const trailingSlash = undefined

export const ASYNC_LOCALES = {
  'de.ts': () => import('../../assets/lang/de.ts' /* webpackChunkName: "lang-de.ts" */),
  'it.ts': () => import('../../assets/lang/it.ts' /* webpackChunkName: "lang-it.ts" */)
}
